/* .ring {
  position: fixed;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border: 2px solid rgba(31, 30, 30, 0.808);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border;
  z-index: 9999;
  pointer-events: none;
} */

* {
  cursor: none;
}

.dot {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  background-color: #817f75;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  pointer-events: none;
  cursor: none;
}

.ring.hovered {
  width: 40px;
  height: 40px;
  border-radius: 20%;
  border-width: 3px;
  border-color: orange;
  z-index: 9999;
}

.dot.hovered {
  background-color: red;
  border-radius: 30%;
  cursor: none;
  pointer-events: none;
}

.dot.clicked {
  background-color: orange;
  border-radius: 30%;
}

.ring.clicked {
  width: 40px;
  height: 40px;
  border-radius: 20%;
  border-width: 3px;
  border-color: red;
  z-index: 9999;

}

@media only screen and (max-width: 700px) {
  .ring, .dot {
    opacity: 0;
  }
}